import { getPageBy, getPageTemplate, getWorks } from "@/lib/wp";
import { renderTemplate } from "@/lib/template";

export default function Home(props) {
  return renderTemplate(props);
}

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation is enabled and a new request comes in
export async function getStaticProps() {
  let page;
  let pageTemplateData;
  try {
    page = await getPageBy("/");
    pageTemplateData = await getPageTemplate("/", "home");
  } catch (error) {
    console.log(error);
  }
  if (!page) {
    return {
      notFound: true,
    };
  }
  const works = await getWorks();

  return {
    props: {
      page: {
        ...page,
        ...pageTemplateData,
      },
      works,
    },
  };
}
